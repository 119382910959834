import React, { useContext } from "react"
import moment from "moment"
import Query from "../../Query"
import StyledImage from "../../../components/StyledImage"
import ReadMorePara from "../../../components/ReadMorePara"
import * as St from "../../Styled"
import Carousel from "../../SliderContainer/carousel"
import { TAB } from "../../../constants/breakpoints"
import {
  MEDIA_TYPE_VIDEO,
  convertToSlug,
  VIDEO_MODE_MAXIMIZED,
  getConfigValue,
  DATA_TYPE_STATION_VIDEO,
} from "../../../constants/props"

import { stationVideoDetailsShareURL } from "../../../constants/share"
import { QUERY_FEATURED_VIDEOS_BY_STATION_ID } from "../../../queries/page/video"
import { QUERY_STATION_BY_ID_NAME_ONLY } from "../../../queries/page/station"
import styled from "styled-components"
import LikeAndShare from "../../LikeAndShare"
import { AppContext } from "../../../contexts/AppContext"

const LikeShare = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: row;
  vertical-align: middle;
  position: unset;
  @media (min-width: ${TAB.minWidth}px) {
    position: relative;
  }
`

const FeaturedVideo = ({ data, station, updatePlayer, ctxPlayer, device }) => {
  const { selectedCountry } = useContext(AppContext)
  const basePrefix = `${process.env.STRAPI_SERVER_URL}`
  const { id, title, uri, subtitles, image, about, viewed, premium } = data
  const stationNameSlug = station && station.slug ? station.slug : "all"
  const stationID = station && station.id ? station.id : "all"
  const videoImage = image && image.url
    ? image.url.startsWith("http")
      ? image.url
      : basePrefix + image.url
    : ""

  const isPlaying =
    ctxPlayer.id === id &&
    ctxPlayer.dataType === DATA_TYPE_STATION_VIDEO &&
    ctxPlayer.playing
      ? true
      : false

  return (
    <St.VideoFeaturedWrapper>
      <St.VideoFeaturedImageWrapper>
        <StyledImage
          borderRadius="0px"
          image={videoImage}
          width={"100%"}
          height={"100%"}
          playing={isPlaying}
          premium={premium}
          imageAlt={title}
          onClickPlay={event => {
            event.preventDefault()
            event.stopPropagation()

            if (!isPlaying) {
              updatePlayer({
                type: MEDIA_TYPE_VIDEO,
                id,
                title,
                uri,
                subtitles,
                preview:
                  image && image.url
                    ? image.url.startsWith("http")
                      ? image.url
                      : basePrefix + image.url
                    : "",
                playlist: [],
                station,
                videoMode: VIDEO_MODE_MAXIMIZED,
                about,
                viewed,
                dataType: DATA_TYPE_STATION_VIDEO,
                playing: true,
              })
            } else {
              updatePlayer({
                ...ctxPlayer,
                playing: false,
              })
            }
          }}
        />
      </St.VideoFeaturedImageWrapper>
      <St.VideoFeaturedContainer
        videoPlaying={ctxPlayer.videoMode === VIDEO_MODE_MAXIMIZED}
      >
        <St.VideoFeaturedBottomTextSection>
          <St.VideoFeaturedColumnText>
            <St.VideoFeaturedTitle> {data.title} </St.VideoFeaturedTitle>
            <St.VideoInfoLabel>
              {subtitles && subtitles.length ? subtitles[0] + subtitles[1] : ""}
            </St.VideoInfoLabel>
            <ReadMorePara
              string={about}
              offset={getConfigValue(device, "box.videos.featured.textOffset")}
            ></ReadMorePara>
          </St.VideoFeaturedColumnText>
          <St.VideoFeaturedColumnText
            style={{ alignItems: "flex-end", flexShrink: 0 }}
          >
            <St.VideoViews>
              <span>{viewed}</span>{" "}
              <span>{viewed === 1 ? "View" : "Views"}</span>
            </St.VideoViews>
            <St.FeaturedSocialIconSection>
              <LikeShare>
                <LikeAndShare
                  shareURL={
                    selectedCountry && selectedCountry.id
                      ? stationVideoDetailsShareURL(
                          id,
                          convertToSlug(title),
                          stationID,
                          stationNameSlug,
                          videoImage
                        )
                      : null
                  }
                  id={id}
                  likeType={DATA_TYPE_STATION_VIDEO}
                  shareID={"featuredvideo_" + id}
                />
              </LikeShare>
            </St.FeaturedSocialIconSection>
          </St.VideoFeaturedColumnText>
        </St.VideoFeaturedBottomTextSection>
      </St.VideoFeaturedContainer>
    </St.VideoFeaturedWrapper>
  )
}

const FeaturedBox = ({ station, videos }) => {
  const { updatePlayer, device, ctxPlayer } = useContext(AppContext)
  videos =
    videos && videos.length
      ? videos.map(video => {
          const v = {
            ...video,
            subtitles: [
              video.show ? video.show.title : "",
              `${station.title} • ${moment(video.updatedAt)
                .startOf("seconds")
                .fromNow()}`,
            ],
          }
          return v
        })
      : []

  let settings = {
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: false,
    variableWidth: false,
    lazyLoad: false,
    arrows: false,
    autoplay: true,
    dots: true,
    autoplaySpeed: 2000,
    dotsClass: "carousel-dots",
  }

  return ctxPlayer.videoMode !== VIDEO_MODE_MAXIMIZED &&
    videos &&
    videos.length > 0 ? (
    <St.VideoFeaturedBox videoPlaying={false}>
      <St.FeaturedBoxContent>
        <St.FeaturedBoxHeading>
          FEATURED VIDEO : {station.title}
        </St.FeaturedBoxHeading>
        <Carousel settings={settings}>
          {videos &&
            videos.map((video, idx) => (
              <FeaturedVideo
                key={video.id}
                device={device}
                data={{
                  ...video,
                }}
                station={{
                  id: station.id,
                  slug: convertToSlug(station.title),
                }}
                {...{ updatePlayer, device, ctxPlayer }}
              />
            ))}
        </Carousel>
      </St.FeaturedBoxContent>
    </St.VideoFeaturedBox>
  ) : (
    <St.VideoFeaturedBox videoPlaying={true}>
      <St.VideoFeaturedContainer
        videoPlaying={ctxPlayer.videoMode === VIDEO_MODE_MAXIMIZED}
      >
        <St.VideoFeaturedBottomTextSection>
          <St.VideoFeaturedColumnText>
            <St.VideoTitle> {ctxPlayer.title} </St.VideoTitle>
            <St.VideoInfoLabel>
              {ctxPlayer.subtitles && ctxPlayer.subtitles.length
                ? ctxPlayer.subtitles[0] + ctxPlayer.subtitles[1]
                : ""}
            </St.VideoInfoLabel>
            <ReadMorePara string={ctxPlayer.about} offset={50}></ReadMorePara>
          </St.VideoFeaturedColumnText>
          <St.VideoFeaturedColumnText
            style={{ alignItems: "flex-end", flexShrink: 0 }}
          >
            <St.VideoViews>
              <span>{ctxPlayer.viewed}</span>{" "}
              <span>{ctxPlayer.viewed === 1 ? "View" : "Views"}</span>
            </St.VideoViews>
            <St.VideoIconsSection>
              <LikeAndShare />
            </St.VideoIconsSection>
          </St.VideoFeaturedColumnText>
        </St.VideoFeaturedBottomTextSection>
      </St.VideoFeaturedContainer>
    </St.VideoFeaturedBox>
  )
}

// Fetches both station basic detail (station name) and featured podcasts that are needed to draw featured podcast box
// and then passed that information to next component to draw
const FeaturedVideos = ({ stationID }) => {
  return (
    <Query
      query={QUERY_STATION_BY_ID_NAME_ONLY}
      variables={{
        id: stationID,
      }}
      dataKey="stationData"
      fetchPolicy="network-only"
    >
      {({ data: { station }, fetchMore }) => {
        return (
          <Query
            query={QUERY_FEATURED_VIDEOS_BY_STATION_ID}
            variables={{
              id: stationID,
            }}
            fetchPolicy="network-only"
          >
            {({ data: { videos } }) => {
              return (
                <FeaturedBox station={station} videos={videos}></FeaturedBox>
              )
            }}
          </Query>
        )
      }}
    </Query>
  )
}

export default FeaturedVideos
