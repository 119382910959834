import gql from "graphql-tag"

export const VIDEO_DETAILS_BY_ID = gql`
  query VideoById($id: ID!) {
    video: mediacontent(id: $id) {
      id
      title
      uri
      about
      contenttype
      viewed
      image {
        id
        url
      }
      presenter {
        id
        title
      }
      premium
      updatedAt
    }
  }
`
export const VIDEO_CONTENT_BY_ID_AND_COUNTRY_ID = gql`
  query VideoByIdAndCountry($id: ID!, $countryId: ID) {
    video: mediacontent(id: $id) {
      id
      title
      uri
      about
      contenttype
      viewed
      image {
        id
        url
      }
      artist {
        id
        title
      }
      presenter {
        id
        title
      }
      country {
        id
        title
      }
      station {
        id
        title
      }
      premium
      updatedAt
    }
    featured: mediacontents(
      sort: "updatedAt:desc"
      where: {
        contenttype: "video"
        country: { id: $countryId }
        featured: true
      }
    ) {
      id
      title
      uri
      contenttype
      viewed
      image {
        id
        url
      }
      artist {
        id
        title
      }
      presenter {
        id
        title
      }
      country {
        id
        title
      }
      station {
        id
        title
      }
      premium
      updatedAt
    }
  }
`

export const VIDEO_CONTENT_BY_ID_AND_STATION_ID = gql`
  query VideoByIdAndStation($id: ID!, $stationId: ID) {
    video: mediacontent(id: $id) {
      id
      title
      uri
      about
      contenttype
      viewed
      image {
        id
        url
      }
      artist {
        id
        title
      }
      presenter {
        id
        title
      }
      country {
        id
        title
      }
      station {
        id
        title
      }
      premium
      updatedAt
    }
    featured: mediacontents(
      sort: "updatedAt:desc"
      where: {
        contenttype: "video"
        station: { id: $stationId }
        featured: true
      }
    ) {
      id
      title
      uri
      contenttype
      image {
        id
        url
      }
      artist {
        id
        title
      }
      presenter {
        id
        title
      }
      country {
        id
        title
      }
      station {
        id
        title
      }
      premium
      updatedAt
    }
  }
`
export const QUERY_FEATURED_VIDEOS_BY_STATION_ID = gql`
  query FeaturedVideosByStationId(
    $id: ID!
    $offset: Int
    $max: Int
    $order: String
  ) {
    videos: mediacontents(
      start: $offset
      limit: $max
      sort: $order
      where: { station: $id, contenttype: "video", featured: true }
    ) {
      id
      title
      uri
      contenttype
      viewed
      image {
        id
        url
      }
      artist {
        id
        title
      }
      station {
        id
        title
      }
      premium
      updatedAt
      about
    }
  }
`
export const QUERY_VIDEOS_BY_STATION_ID = gql`
  query VideosByStationId(
    $offset: Int
    $max: Int
    $stationID: ID!
    $order: String
  ) {
    videos: mediacontents(
      start: $offset
      limit: $max
      sort: $order
      where: { contenttype: "video", station: $stationID }
    ) {
      id
      title
      uri
      contenttype
      viewed
      image {
        id
        url
      }
      artist {
        id
        title
      }
      station {
        id
        title
      }
      premium
      updatedAt
      about
    }
  }
`

export const QUERY_FEATURED_VIDEOS_BY_ARTIST_ID = gql`
  query FeaturedVideosByArtistID(
    $artistID: ID!
    $offset: Int
    $max: Int
    $order: String
  ) {
    videos: mediacontents(
      start: $offset
      limit: $max
      sort: $order
      where: { artist: $artistID, contenttype: "video", featured: true }
    ) {
      id
      title
      uri
      contenttype
      viewed
      image {
        id
        url
      }
      artist {
        id
        title
        country {
          id
          title
        }
      }
      premium
      updatedAt
      about
    }
  }
`
export const QUERY_VIDEOS_BY_ARTIST_ID = gql`
  query VideosByArtistID(
    $offset: Int
    $max: Int
    $artistID: ID!
    $order: String
  ) {
    videos: mediacontents(
      start: $offset
      limit: $max
      sort: $order
      where: { contenttype: "video", artist: $artistID }
    ) {
      id
      title
      uri
      contenttype
      viewed
      image {
        id
        url
      }
      artist {
        id
        title
        country {
          id
          title
        }
      }
      premium
      updatedAt
      about
    }
  }
`
