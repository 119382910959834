import React from "react"
import ContentStationVideos from "../components/ContentStationVideos"
import queryString from "query-string"

const Videos = ({ location }) => {
  const queryParams =
    location && location.search ? queryString.parse(location.search) : {}
  return (
    <React.Fragment>
      <ContentStationVideos
        stationID={queryParams.__sid}
        stationName={queryParams.s}
      />
    </React.Fragment>
  )
}

export default Videos
