import React, { useState } from "react"
import { useQuery } from "@apollo/client"
import * as St from "../../Styled"
import moment from "moment-timezone"
import { QUERY_VIDEOS_BY_STATION_ID } from "../../../queries/page/video"
import VideoCard from "./VideoCard"

const AllVideos = props => {

  let [sortCriteria, setSortCriteria] = useState("title:asc")

  const onSortChange = event => {
    // FIND COUNTRY IN COUNTRIES ARRAY WITH SELECTED ID AND EXPAND IT TO CREATE NEW OBJECT AND SET AS NEW SELECTED COUNTRY
    setSortCriteria(event.target.value)
  }

  const { data, loading } = useQuery(QUERY_VIDEOS_BY_STATION_ID, {
    variables: {
      order: sortCriteria,
      stationID: props.stationID,
    },
    fetchPolicy: "network-only",
  })

  if (!loading) {
    const videos =
      data && data.videos
        ? data.videos.map((video, _) => {
            const v = {
              ...video,
              clickableTitle: false,
              subtitles: [
                video.show ? video.show.title : "",
                `${video.station.title} • ${moment(video.updatedAt)
                  .startOf("seconds")
                  .fromNow()}`,
              ],
            }
            return v
          })
        : []

    return (
      <St.AllCardsSection>
        <St.AllCardsTopSection>
          <St.AllCardsHeading>
            VIDEO ON DEMAND : {props.stationName}
          </St.AllCardsHeading>
          <St.SortSpan>
            SORT :
            <St.SortSelect onChange={onSortChange}>
              <St.SortOption
                value="title:asc"
                selected={sortCriteria === "title:asc" ? true : false}
              >
                A-Z
              </St.SortOption>
              <St.SortOption
                value="title:desc"
                selected={sortCriteria === "title:desc" ? true : false}
              >
                Z-A
              </St.SortOption>
            </St.SortSelect>
          </St.SortSpan>
        </St.AllCardsTopSection>
        <St.VideoGridAllWrapper>
          {videos &&
            videos.length &&
            videos.map((video, i) => (
              <VideoCard
                key={i}
                video={video}
                station={{
                  id: props.stationID,
                  slug: props.stationName,
                }}
              />
            ))}
        </St.VideoGridAllWrapper>
      </St.AllCardsSection>
    )
  } else {
    return null
  }
}

export default AllVideos
