import React, { useContext } from "react"
import StyledImage from "../../StyledImage"
import * as St from "../../Styled"
import { AppContext } from "../../../contexts/AppContext"
import LikeAndShare from "../../LikeAndShare"
import {
  MEDIA_TYPE_VIDEO,
  convertToSlug,
  VIDEO_MODE_MAXIMIZED,
  DATA_TYPE_STATION_VIDEO,
} from "../../../constants/props"
import styled from "styled-components"
import { stationVideoDetailsShareURL } from "../../../constants/share"
import useRecordUserData from "../../../hooks/useRecordUserData"

const LikeShare = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  flex-direction: row;
  vertical-align: middle;
  flex: 1;
  position: relative;
`

const VideoCard = ({ video, station }) => {
  const basePrefix = `${process.env.STRAPI_SERVER_URL}`
  const { selectedCountry, updatePlayer, userState, ctxPlayer } = useContext(
    AppContext
  )
  const stationNameSlug = station && station.slug ? station.slug : "all"
  const recordUserData = useRecordUserData({
    type: DATA_TYPE_STATION_VIDEO,
    id: video ? video.id : null,
    user: userState ? userState.id : null,
    viewed: video.viewed != null ? video.viewed : 0,
  })
  const stationID = station && station.id ? station.id : "all"
  if (!video) return null
  const { id, uri, title } = video

  const isPlaying =
    ctxPlayer.id === id &&
    ctxPlayer.dataType === DATA_TYPE_STATION_VIDEO &&
    ctxPlayer.playing
      ? true
      : false

  const image =
    video.image && video.image.url
      ? video.image.url.startsWith("http")
        ? video.image.url
        : basePrefix + video.image.url
      : ""
  return (
    <St.VideoGridWrapper>
      <St.VideoGridImageWrapper>
        <StyledImage
          image={image}
          width={"100%"}
          height={"100%"}
          borderRadius={"0px"}
          playing={isPlaying}
          premium={video.premium}
          imageAlt={title}
          onClickPlay={event => {
            event.preventDefault()
            event.stopPropagation()

            if (!isPlaying) {
              updatePlayer({
                type: MEDIA_TYPE_VIDEO,
                id,
                title,
                uri,
                preview:
                  video.image && video.image.url
                    ? video.image.url.startsWith("http")
                      ? video.image.url
                      : basePrefix + video.image.url
                    : "",
                playlist: [],
                station: station,
                artist: null, // station is owner of this video (and not artist)
                videoMode: VIDEO_MODE_MAXIMIZED,
                subtitles: video.subtitles,
                about: video.about,
                viewed: video.viewed != null ? video.viewed : 0,
                dataType: DATA_TYPE_STATION_VIDEO,
                playing: true,
              })
              recordUserData()
            } else {
              updatePlayer({
                ...ctxPlayer,
                playing: false,
              })
            }

            window.scrollTo({
              top: 0,
              behavior: "smooth",
            })
          }}
        />
      </St.VideoGridImageWrapper>

      <St.VideoGridInfoWrapper>
        <St.VideoGridTitle> {title} </St.VideoGridTitle>
        <St.VideoGridSingleLineText>{video.about}</St.VideoGridSingleLineText>
        {video.station && (
          <St.VideoGridSingleLineText>
            {video.subtitles && video.subtitles.length > 1
              ? video.subtitles[1]
              : ""}
          </St.VideoGridSingleLineText>
        )}
        <LikeShare>
          <LikeAndShare
            shareURL={
              selectedCountry && selectedCountry.id
                ? stationVideoDetailsShareURL(
                    id,
                    convertToSlug(title),
                    stationID,
                    stationNameSlug,
                    image
                  )
                : null
            }
            id={id}
            likeType={DATA_TYPE_STATION_VIDEO}
            shareID={"allvideo_" + id}
          />
        </LikeShare>
      </St.VideoGridInfoWrapper>
    </St.VideoGridWrapper>
  )
}

export default VideoCard
