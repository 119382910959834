import React, { useContext } from "react"
import FeaturedVideos from "./FeaturedVideos"
import AllVideos from "./AllVideos"
import * as St from "../Styled"
import { AppContext } from "../../contexts/AppContext"
import { DEVICE_LANDSCAPE } from "../../constants/breakpoints"
import { VIDEO_MODE_MAXIMIZED } from "../../constants/props"

const ContentFeaturedVideos = props => {
  const { device, ctxPlayer } = useContext(AppContext)

  return device !== DEVICE_LANDSCAPE ||
    ctxPlayer.videoMode !== VIDEO_MODE_MAXIMIZED ? (
    <St.ContentParent>
      <St.AdWrapper>
        <St.LeftAdSection />
        <St.LeftAd2Section />
      </St.AdWrapper>
      <St.ContentWrapper>
        <FeaturedVideos
          stationID={props.stationID}
          stationName={props.stationName}
        />
        <St.CenterContentSection>
          <AllVideos
            stationID={props.stationID}
            stationName={props.stationName}
          />
        </St.CenterContentSection>
        <St.BottomAdSection />
      </St.ContentWrapper>
      <St.AdWrapper>
        <St.RightAdSection />
        <St.RightAd2Section />
      </St.AdWrapper>
    </St.ContentParent>
  ) : null
}

export default ContentFeaturedVideos
